import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  GetUserStore,
  setLogOut,
  updateIsLoadingUserStore,
  updateUserStore,
} from 'redux/slices/Auth/authSlice';
import { useGetAbilitiesQuery } from 'redux/api/Abilities/Abilities.api';
import { useEffect } from 'react';
import { IAbilitiesApi } from 'types/models/Abilities/Abilities';
import { arrayIsEmpty } from 'utils/helpers/array.helpers';
import {
  setIsErrorRouteConfig,
  setIsLoadingRouteConfig,
  setRouteConfig,
} from 'redux/slices/app/appSlice';
import { extractRouteConfig } from 'utils/helpers/path.helpers';
import { useGetUserByIdQuery } from 'redux/api/users/users.api';
import { isNil } from 'lodash';
export default function useInitApp() {
  const userData = useAppSelector(GetUserStore);
  const dispatch = useAppDispatch();
  const { data: userDetails, isLoading: isLoadingUser } = useGetUserByIdQuery(
    userData?.id as number,
    {
      skip: isNil(userData?.id),
    },
  );
  const {
    data: abilitiesData,
    isLoading: isLoadingRouteConfig,
    isError: isErrorRouteConfig,
  } = useGetAbilitiesQuery(
    {},
    {
      skip: !userData?.isSuper || isNil(userData?.id),
    },
  );
  const setUserRouteConfig = (abilities: IAbilitiesApi[]) => {
    if (arrayIsEmpty(abilities)) {
      dispatch(setLogOut());
    } else {
      dispatch(setRouteConfig(extractRouteConfig(abilities)));
    }
  };

  useEffect(() => {
    dispatch(setIsLoadingRouteConfig(isLoadingRouteConfig));
    dispatch(setIsErrorRouteConfig(isErrorRouteConfig));
  }, [isLoadingRouteConfig, isErrorRouteConfig]);

  // this useEffect is for updating the route config depending on the user abilities
  useEffect(() => {
    if (userData?.isSuper) {
      if (abilitiesData) setUserRouteConfig(abilitiesData);
    } else if (userData?.profile?.abilities) {
      setUserRouteConfig(userData.profile.abilities);
    }
  }, [userData, abilitiesData]);

  // this useEffect is for updating the user store to get the user details
  useEffect(() => {
    dispatch(
      updateIsLoadingUserStore({
        isLoadingUser,
      }),
    );
    if (userDetails) {
      dispatch(
        updateUserStore({
          user: userDetails,
        }),
      );
    }
  }, [userDetails, isLoadingUser]);
}
