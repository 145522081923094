import { GlobalVariables } from 'config/constant';
import {
  IOrder,
  IOrderApi,
  IOrderDates,
  IOrderDatesApi,
  IOrderDetails,
  IOrderDetailsApi,
  IOrderLogs,
  IOrderLogsApiResponse,
  IOrderResponse,
  IOrderResponseApi,
} from 'types/models/Ordre/Ordre.type';
import { isValidStatusOrder } from 'utils/helpers/string.helpers';
import {
  transformClientItemResponse,
  transformGetClientByIdResponse,
} from 'redux/api/client/clientApi.transform';
import { transformItemArticleResponse } from 'redux/api/article/articlesApi.transform';
import { transformItemUserResponse } from 'redux/api/users/users.transform';
import {
  ICartDetails,
  ICartDetailsApiResponse,
  ICartDiffs,
  ICartDiffsApi,
  ICartLogs,
  ICartLogsApiResponse,
} from 'types/models/Cart/Cart.schema';
import { ClaimerTypeEnum } from 'types/interfaces/sharedType.type';
import { IClient, IClientApi } from 'types/models/Client/client';
import { IUser, IUserAPI } from 'types/models/User/Team/User';
import { Article } from 'types/models/Article/article';
export const transformItemOrdreResponse = (ordreItem: IOrderApi): IOrder => {
  return {
    id: ordreItem.id,
    clientId: ordreItem.clientId,
    clientInfo: {
      cin: ordreItem?.clientInfo?.cin,
      firstName: ordreItem?.clientInfo?.firstName,
      lastName: ordreItem?.clientInfo?.lastName,
      phoneNumber: ordreItem?.clientInfo?.phoneNumber,
    },
    clientAddress: {
      governorate: ordreItem?.clientAddress?.governorate,
      latitude: ordreItem?.clientAddress?.latitude,
      line: ordreItem?.clientAddress?.line,
      longitude: ordreItem?.clientAddress?.longitude,
      postalCode: ordreItem?.clientAddress?.postalCode,
      streetNumber: ordreItem?.clientAddress?.streetNumber,
    },
    shopInfo: {
      name: ordreItem?.shopInfo?.name,
    },
    total: {
      discountAmount: ordreItem?.total?.discountAmount,
      shippingFee: ordreItem?.total?.shippingFee,
      subAmount: ordreItem?.total?.subAmount,
      totalAmount: ordreItem?.total?.totalAmount,
      taxFreeAmount: ordreItem?.total?.taxFreeAmount,
    },
    orderDate: ordreItem?.orderDate,
    status: ordreItem.status,
    addressId: ordreItem?.addressId,
    note: ordreItem?.note,
    originOrderId: ordreItem?.originOrderId,
    ref: ordreItem?.ref,
    promoCode: ordreItem?.promoCode,
    shopId: ordreItem?.shopId,
    warehouse: ordreItem?.warehouse
      ? {
          nameFr: ordreItem.warehouse?.nameFr,
          surfaceArea: ordreItem.warehouse?.surfaceArea,
          manager: ordreItem.warehouse?.manager,
          taxNumber: ordreItem.warehouse?.taxNumber,
          type: ordreItem.warehouse?.type,
          zoneId: ordreItem.warehouse?.zoneId,
          class: ordreItem.warehouse?.class,
          deletedAt: ordreItem.warehouse?.deletedAt,
          status: ordreItem.warehouse?.status,
          nameAr: ordreItem.warehouse?.nameAr,
          id: ordreItem.warehouse?.id,
        }
      : null,
    synchedAt: ordreItem?.synchedAt,
    agent: ordreItem?.agent
      ? {
          id: ordreItem?.agent?.id,
          isSuper: ordreItem?.agent?.isSuper,
          status: ordreItem?.agent?.status === GlobalVariables.STATUS.ACTIVE,
          sageCode: ordreItem?.agent?.sageCode,
          email: ordreItem?.agent?.email,
          lastName: ordreItem?.agent?.lastName,
          firstName: ordreItem?.agent?.firstName,
        }
      : undefined,
    isSynched: ordreItem?.isSynched,
    validationStatus: isValidStatusOrder(ordreItem?.status),
    isUnderCutOff: ordreItem?.isUnderCutOff,
    client: ordreItem?.client ? transformGetClientByIdResponse(ordreItem.client) : undefined,
    explodedOrders: transformExplodedOrders(ordreItem?.explodedOrders),
    authorizations: {
      canCancel: ordreItem?.authorizations?.canCancel,
      canConfirm: ordreItem?.authorizations?.canConfirm,
      canUpdate: ordreItem?.authorizations?.canUpdate,
    },
    createdBy: ordreItem.createdBy ? transformCreatedByOrder(ordreItem) : null,
    isOriginOrder: ordreItem?.isOriginOrder,
  };
};
function transformCreatedByOrder(
  orderItem: IOrderApi,
):
  | (Partial<IUser> & { claimerType: ClaimerTypeEnum.User })
  | (Partial<IClient> & { claimerType: ClaimerTypeEnum.Client })
  | null {
  if (!orderItem) return null;

  // orderItem ?  orderItem.createdBy.claimerType === ClaimerTypeEnum.Client ? return transformClientItemResponse(orderItem) : return transformItemUserResponse(orderItem.createdBy) : null

  return orderItem.createdBy?.claimerType === ClaimerTypeEnum.Client
    ? {
        ...transformClientItemResponse(orderItem.createdBy as IClientApi),
        claimerType: ClaimerTypeEnum.Client,
      }
    : {
        ...transformItemUserResponse(orderItem.createdBy as IUserAPI),
        claimerType: ClaimerTypeEnum.User,
      };
}
function transformExplodedOrders(explodedOrders: IOrderApi[] | undefined): IOrder[] | undefined {
  if (!explodedOrders) return undefined;
  if (explodedOrders.length === 0) return [];
  return explodedOrders.map(transformItemOrdreResponse);
}

export const transformOrdreResponse = (response: IOrderResponseApi): IOrderResponse => {
  const results = response?.results;
  const data: IOrder[] = results?.map(transformItemOrdreResponse);
  return {
    data,
    total: response?.total,
    lastPage: response?.lastPage,
    nextPage: response?.nextPage,
    previousPage: response?.previousPage,
    totalPerPage: response?.totalPerPage,
  };
};
export const transformOrderLogsResponse = (response: IOrderLogsApiResponse[]): IOrderLogs[] => {
  if (!response) return [];
  return response.map(transformLogItem);
};
const transformLogItem = (item: IOrderLogsApiResponse): IOrderLogs => ({
  id: item?.id,
  createdAt: item?.createdAt,
  event: item?.event,
  updatedAt: item?.updatedAt,
  user: item.user ? transformItemUserResponse(item.user) : null,
  payload: transformItemOrdreResponse(item?.payload),
  data:
    item.data && Object.keys(item.data).length > 0
      ? {
          latitude: item.data.latitude,
          longitude: item.data.longitude,
          distanceFromShop: item.data.distanceFromShop,
        }
      : null,
  diffs: item.diffs ? transformCartDiffs(item.diffs) : null,
});

export const transformOrdreDetailsResponse = (response: IOrderDetailsApi): IOrderDetails => {
  return {
    id: response?.id,
    ref: response?.ref,
    promoCode: response?.promoCode,
    shopId: response?.shopId,
    warehouseId: response?.warehouseId,
    explodedOrders: response?.explodedOrders,
    addressId: response?.addressId,
    note: response?.note,
    originOrderId: response?.originOrderId,
    idemKey: response?.idemKey,
    client: response?.client ? transformClientItemResponse(response.client) : null,
    clientAddress: {
      streetNumber: response?.clientAddress?.streetNumber,
      postalCode: response?.clientAddress?.postalCode,
      line: response?.clientAddress?.line,
      longitude: response?.clientAddress?.longitude,
      latitude: response?.clientAddress?.latitude,
      governorate: response?.clientAddress?.governorate,
    },
    clientId: response?.clientId,
    status: response?.status,
    clientInfo: {
      cin: response?.clientInfo?.cin,
      firstName: response?.clientInfo?.firstName,
      lastName: response?.clientInfo?.lastName,
      phoneNumber: response?.clientInfo?.phoneNumber,
    },
    orderDate: response?.orderDate,
    validationStatus: isValidStatusOrder(response?.status),
    total: {
      discountAmount: response?.total?.discountAmount,
      shippingFee: response?.total?.shippingFee,
      subAmount: response?.total?.subAmount,
      totalAmount: response?.total?.totalAmount,
      taxFreeAmount: response?.total?.taxFreeAmount,
    },
    paymentIntent: {
      id: response?.paymentIntent?.id,
      relatedIntents: response?.paymentIntent?.relatedIntents,
      paymentMethod: response?.paymentIntent?.paymentMethod,
      clientId: response?.paymentIntent?.clientId,
      amount: response?.paymentIntent?.amount,
    },
    shopInfo: {
      name: response?.shopInfo?.name,
    },
    items: response?.items?.map((item) => {
      return {
        articleId: item?.articleId,
        idItem: item?.id,
        total: {
          taxFreeAmount: item?.total?.taxFreeAmount,
          totalAmount: item?.total?.totalAmount,
        },
        article: transformItemArticleResponse(item?.article) as Article,

        quantities: item?.quantities?.map((quantity) => {
          return {
            qty: quantity?.qty,
            packingType: quantity?.packingType,
            taxFreeAmount: quantity?.taxFreeAmount,
            totalAmount: quantity?.totalAmount,
            packingBasePrice: quantity?.packingBasePrice,
            packingPrice: quantity?.packingPrice,
          };
        }),
      };
    }),
    synchedAt: response?.synchedAt,
    isUnderCutOff: response?.isUnderCutOff,
    isSynched: response?.isSynched,
    authorizations: {
      canCancel: response?.authorizations?.canCancel,
      canConfirm: response?.authorizations?.canConfirm,
      canUpdate: response?.authorizations?.canUpdate,
    },
  };
};
export const transformOrderDatesResponse = (responseApi: IOrderDatesApi[]): IOrderDates[] => {
  return responseApi?.map((response) => {
    return {
      orderId: response?.orderId,
      catalog: {
        id: response?.catalog?.id,
        level: response?.catalog?.level,
        name: response?.catalog?.name,
        sageId: response?.catalog?.sageId,
        status: response?.catalog?.status === GlobalVariables.STATUS.ACTIVE,
        nameFr: response?.catalog?.nameFr,
        nameAr: response?.catalog?.nameAr,
        deletedAt: response?.catalog?.deletedAt,
      },
      expectedDeliveryDate: response?.expectedDeliveryDate,
      orderRef: response?.orderRef,
      expectedShipmentDate: response?.expectedShipmentDate,
    };
  });
};
export const transformPayloadCartItem = (payloadData: ICartDetailsApiResponse): ICartDetails => ({
  createdAt: payloadData.createdAt,
  idemKey: payloadData.idemKey,
  shopId: payloadData.shopId,
  updatedAt: payloadData.updatedAt,
  isCurrent: payloadData.isCurrent,
  name: payloadData.name,

  items: payloadData.items.map((item) => ({
    createdAt: item.createdAt,
    updatedAt: item.updatedAt,
    cartId: item.cartId,
    articleId: item.articleId,
  })),

  total: {
    taxFreeAmount: payloadData.total.taxFreeAmount,
    discountAmount: payloadData.total.discountAmount,
    shippingFee: payloadData.total.shippingFee,
    subAmount: payloadData.total.subAmount,
  },
});
const transformCartDiffs = (diffs: ICartDiffsApi): ICartDiffs => ({
  items: diffs.items.map((item) => ({
    article: {
      name: item.article.name,
    },
    type: item.type,
    packings: item.packings?.map((packing) => ({
      type: packing.type,
      packingType: packing.packingType,
      before: packing?.before
        ? {
            quantity: packing.before.quantity,
          }
        : undefined,
      after: packing?.after
        ? {
            quantity: packing.after.quantity,
          }
        : undefined,
    })),
  })),
});
export const transformCartLogItem = (item: ICartLogsApiResponse): ICartLogs => ({
  createdAt: item.createdAt,
  event: item.event,
  id: item.id,
  updatedAt: item.updatedAt,
  user: item.user ? transformItemUserResponse(item.user) : null,
  payload: transformPayloadCartItem(item.payload),
  diffs: item.diffs ? transformCartDiffs(item.diffs) : null,
});
export const transformCartLogsResponse = (response: ICartLogsApiResponse[]): ICartLogs[] =>
  response.map(transformCartLogItem);
